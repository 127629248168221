import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [inputData, setInputData] = useState({
    fullname: '',
    gioitinh: '',
    masobhyt: '',
    ngaysinh: '',
    cccd: '',
    sdt: '',
    diachi: '',
    BHXH_HISTORY: [],
    avatarPath: '',
    ct14Path: ''
  });

  const [rows, setRows] = useState([
    { start: '2024-01', stop: '2024-01', donvi: 'CTCP Đầu tư Xây dựng Thương mại Sơn Trang', chucdanh: 'Đầu bếp', noilamvice: 'TP.HCM', loaitien: 'VND', mucdongbhxh: '36.000.000', mucluong: '41.200.000' }
  ]);

  const handleInputChange = (event) => {
    setInputData({ ...inputData, [event.target.name]: event.target.value });

    const inputDataJson = JSON.stringify(inputData);
    console.log(inputDataJson);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    inputData.BHXH_HISTORY = rows;
    inputData.avatarPath = inputData.masobhyt + '_avatar.png';
    inputData.ct14Path = inputData.masobhyt + '_ct14.png';

    const formData = new FormData();
    formData.append('data', JSON.stringify(inputData));

    // Append the files
    formData.append('profile', fileProfile);
    formData.append('ct14', fileCT14);

    try {
      // Send the FormData object to the server using fetch
      const response = await fetch('http://45.32.211.207/api/update-or-create-profile', {
        method: 'POST',
        body: formData
      });
  
      if (!response.ok) {
        // If the response was not successful, throw an error
        throw new Error('An error occurred while submitting the form.');
      }
  
      const data = await response.json();
      if (data.success) {
        toast.success('Form submitted successfully!');
      } else {
        toast.error('An error occurred while submitting the form: \n' + data.message);
      }
      // If the response was successful, show a success toast
    } catch (error) {
      // If an error occurred during the fetch operation, show an error toast
      toast.error(error.message);
    }
  };

  const handleRowContentChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  const handleCLoneRow = () => {
    const lastRow = rows[rows.length - 1];
    setRows([...rows, { ...lastRow }]);
  };

  const [fileProfile, setFileProfile] = useState('');
  const [fileCT14, setFileCT14] = useState('');

  const handleProfileFileChange = (event) => {
    const file = event.target.files[0];
    setFileProfile(file);
  }

  const handleCT14FileChange = (event) => {
    const file = event.target.files[0];
    setFileCT14(file);
  }


  return (
    <div className="App">
      <ToastContainer />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="formsContainer">
          <form className="form" onSubmit={handleFormSubmit}>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '50px' }}>
              <div>
                <div className="divStyle" >
                  <Typography variant="h6">Họ tên:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="text" name="fullname" value={inputData.fullname} onChange={handleInputChange} size="small" />
                </div>
                <div className="divStyle" >
                  <Typography variant="h6">Giới tính:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="text" name="gioitinh" value={inputData.gioitinh} onChange={handleInputChange} size="small" />
                </div>
                <div className="divStyle" >
                  <Typography variant="h6">Mã số BHYT:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="text" name="masobhyt" value={inputData.masobhyt} onChange={handleInputChange} size="small" />
                </div>
              </div>
              <div>
                <div className="divStyle">
                  <Typography variant="h6">Ngày sinh:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="date" name="ngaysinh" value={inputData.ngaysinh} onChange={handleInputChange} size="small" />
                </div>
                <div className="divStyle">
                  <Typography variant="h6">CCCD:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="text" name="cccd" value={inputData.cccd} onChange={handleInputChange} size="small" />
                </div>
                <div className="divStyle">
                  <Typography variant="h6">SDT:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="text" name="sdt" value={inputData.sdt} onChange={handleInputChange} size="small" />
                </div>
              </div>
              <div>
                <div className="divStyle">
                  <Typography variant="h6">Địa chỉ:</Typography>
                  <TextField className="textEdit" inputProps={{ style: { color: 'white' } }} type="text" name="diachi" value={inputData.diachi} onChange={handleInputChange} size="small" />
                </div>
                <div className="divStyle">
                  <Typography variant="h6">Ảnh Profile:</Typography>
                  <input type="file" accept="image/*" style={{ flex: 1, marginLeft: 20 }} onChange={handleProfileFileChange} />
                </div>
                <div className="divStyle">
                  <Typography variant="h6">Ảnh CT14:</Typography>
                  <input type="file" accept="image/*" style={{ flex: 1, marginLeft: 20 }} onChange={handleCT14FileChange} />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: '50px' }}>
              <div className="field">
                <Typography variant="body1">Ngày bắt đầu:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Ngày kết thúc:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Đơn vị:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Chức danh:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Nơi làm việc:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Loại tiền:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Mức đóng BHXH:</Typography>
              </div>
              <div className="field">
                <Typography variant="body1">Mức lương:</Typography>
              </div>
            </div>
            {rows.map((row, i) => (
              <div key={i} className="row">
                <div className="field">
                  <TextField type="month" inputProps={{ style: { color: 'white' } }} name="start" value={row.start} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="month" inputProps={{ style: { color: 'white' } }} name="stop" value={row.stop} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="text" inputProps={{ style: { color: 'white' } }} name="donvi" value={row.donvi} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="text" inputProps={{ style: { color: 'white' } }} name="chucdanh" value={row.chucdanh} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="text" inputProps={{ style: { color: 'white' } }} name="noilamvice" value={row.noilamvice} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="text" inputProps={{ style: { color: 'white' } }} name="loaitien" value={row.loaitien} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="text" inputProps={{ style: { color: 'white' } }} name="mucdongbhxh" value={row.mucdongbhxh} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
                <div className="field">
                  <TextField type="text" inputProps={{ style: { color: 'white' } }} name="mucluong" value={row.mucluong} onChange={e => handleRowContentChange(e, i)} variant='outlined' size="small" />
                </div>
              </div>
            ))}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button type="button" onClick={handleCLoneRow}
                style={{
                  fontSize: '14px',
                  padding: '10px 20px',
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  transitionDuration: '0.4s'
                }}>Clone</button>
            </div>

            <button type="submit" style={{
              fontSize: '20px',
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              transitionDuration: '0.4s',
              outline: 'none', // remove focus outline
              marginTop: '20px'
            }}
              tabIndex="-1" // remove button from tab order
              onClick={handleFormSubmit}
              onMouseOver={e => e.currentTarget.style.backgroundColor = '#45a049'}
              onMouseOut={e => e.currentTarget.style.backgroundColor = '#4CAF50'}>Submit</button>
          </form>
        </div>
      </header>
    </div>
  );
}

export default App;